// -----------------------------------//
// NAVIGATION WRAPPERS
// -----------------------------------//


nav#navholder {

    position: relative; z-index: 400; background: $nav-bg;@extend .clearfix;box-shadow: 0 4px 0 0 rgba(0,0,0,0.1);
    > .wrapper { width: 990px; margin: 0 auto; }

}

// -----------------------------------//
// MAIN NAVIGATION
// -----------------------------------//

ul.nav { 

	display: table; width: 990px; margin: 0; list-style: none; 

	// Top Level List

	li {
	    margin: 0; padding: 0; background: none;
	    position: relative; display: table-cell; text-align: center; white-space: nowrap;
	    a {
	    	height: 50px; line-height: 52px; display: block; text-align: center;font-size: 17px;color: #fff;font-weight: 300;
	    }
	}
	> li:hover > a, > li > a:hover {text-decoration: none;background: rgba(0, 0, 0, 0.2);}

	// 2nd Level List

	li {
		ul { position: absolute; left: -999em; margin: 0; padding: 0 20px 20px; background: none; opacity: 0;visibility: hidden;transition: (all 0.3s ease-in-out);}
		&:hover ul { left: auto; margin-left: -20px; opacity: 1;visibility: visible;transition: (all 0.3s ease-in-out);}
		&:active ul{transition: (opacity 0.1s ease-in-out);}
	}

	// 2nd Level List Items

	li li {
		float: none; display: block; padding: 0; height: auto; line-height: 35px;
		a { background: $dop-down-bg; color: #fff; height: 28px; line-height: 28px; padding: 3px 12px; min-width: 150px; float: none; top: auto; display: block; position: static; text-align: left; text-decoration: none;font-size: 13px;}
		&:hover, &:hover > a, a:hover, a:focus { background: $dop-down-hover; color: #fff; }
	}

	// Prepend 'more' arrow to parent list items

	li li a.parent:after { margin: -1px 0 0 10px; content: url('/i/design/lite/nav-right-arrow-white.png'); opacity: 1; line-height: 1; }

	// 3rd Level List 
	
	ul ul { z-index: 505; }
	li:hover ul ul { left: 100%; top: 0; opacity: 0;visibility: hidden;transition: (all 0.3s ease-in-out);}
	ul li:hover ul { left: 100%; top: 0; opacity: 1;visibility: visible;transition: (all 0.3s ease-in-out);}
	
}

nav.fixed-nav{width: 100%;position: fixed !important;top:0;left: 0;}