// -----------------------------------//
// ELEMENTS COLOURS
// -----------------------------------//
$slideOut-panel-bg: rgba(92, 161, 167, .8);

$link-bg-colour: blue;
$link-bg-colour-hover: #75c3ca;
$links-colour: yellow;

$slide-click-colour: #fff;


/* Full container */
#slideOut {
	position: absolute;
	width: auto;
	height: auto;
	top: 250px;
	left: 0;
	background: $slideOut-panel-bg;
	overflow: hidden;
	font-family: 'Avenir', sans-serif;
	padding: 0;
    z-index: 50;
}


/* Click to slide */
#slideClick {
	float: right;
	height: 100%;
	width: 87px;
	cursor: pointer;
	position: relative;
	cursor: pointer;
	font-family: $headings;
	background: url(/i/design/bg-quick-links.png) no-repeat left bottom #303a4e;
	box-shadow:inset -18px 0 0 0 #90ccd1;
	span{
		width: 160px;
		height: 70px;
		position: absolute;
		top: 50%;
		left: 50%;
		font-size: 25px;
		color: $slide-click-colour;
		font-weight: 500;
		white-space: nowrap;
		transform: rotate(-90deg);
		margin-left: -76px;
		margin-top: -37px;
		letter-spacing: 0.02em;
		&:before{
			content: "";
			width: 7px;
			height: 12px;
			background: url(/i/design/arrow-open-close.png) no-repeat center center;
			position: absolute;
			left: 50%;
			top: 83%;
			margin-left: -7px;
			transform: rotate(90deg);
		}
	}
}

/* Content links */
#slideContent {
	width: auto;
	height: auto;
	padding: 0;
	margin: 0;
	float: left;
	color: #EFEFEF;
	text-align: center;
	
}


/* Box with links  */
ul.quick-links{
	width: 248px;
	display: block;
	padding: 20px 0 20px 0;
	text-align: center;
	display: inline-block;
	overflow: hidden;
	
	
	li{
		background: none;
		margin: 0;
		padding: 0;
		display: block;
		
		/* Border radius top */
		&:first-of-type{
			a{}
		}
		
		/* Border radius bottom */
		&:last-of-type{
			a{box-shadow: none;}
		}
		
		a{
			background: url(/i/design/svg/ic-quick-links.svg) no-repeat 27px 14px;
			color: $links-colour;
			font-size: 23px;
			display: block;
			text-align: left;
			padding: 7px 5px 7px 68px;
			color: #fff;
			font-weight: 300;
			&:hover{
				background-color: $link-bg-colour-hover;
				text-decoration: none;
			}
		}
	}
}

/* Change the arrow  */
.open span::before{transform: rotate(-90deg) !important;}
.flip { display: none; }

@media only screen and (max-width: 750px) {
	#slideOut{
		position: relative;
		top: 44px;
		left: 0;
		padding: 3px 3% 0 2%;
		overflow: visible;
		height: auto !important;
		margin: 0 0;
	}

	#slideClick{
		display: none;
		span{
			transform: rotate(0);
			position: absolute;
			top: 0;
			left: 0;
			width: auto;
			height: auto;
			margin-left: 0;
			margin-top: 0;
			position: relative;
		}
	}
	
	#slideContent{
		float: none;
	}
	
	ul.quick-links{
		width: 100%;
		li{
			a{
				padding: 6px 0 6px 20%;
				font-size: 1.1em;
				background-position: 8% 10px;
			}
		}
	}

	
	.flip{
		z-index: 800;
		margin: 0;
		padding:0 0 5px 0;
		cursor: pointer;
		font-size: 16px;
		color: $slide-click-colour;
		display: block;
		
		img{display: block; margin: 0 auto; transform: rotate(90deg);}
	}

	.open img{transform: rotate(-90deg);}
}