
.link-partnership{display: none;}
.thpt-panel {width: 240px; display: inline-block; position: absolute; top: -1px; right: 0;z-index: 500;}
div.panel {height: auto; display: none; background: rgba(0, 161, 185, .9);margin-bottom: -11px;padding: 26px 18px 12px 18px;border-radius:  0;
	p{margin: 0 0 19px 0; color: #fff; font-size: 14px; text-align: center; letter-spacing: 0.02em; line-height: 157%;font-weight: 300;
		a{display: block;background: #30394e;color: #fff;font-weight: 500;margin: 15px 27px 0 27px; height: 30px; line-height: 31px; letter-spacing: 0.08em;
			&:hover{text-decoration: none;background: #2bd0e9;}
		}
	}
}
p.flip-thpt { cursor: pointer;  background: url(/i/design/bg-thpt.png) no-repeat left top #007f92;margin: 0;padding: 11px 0 25px 0;position: relative;text-align: center;
	span{padding: 0;font-size: 19px;color: #fff;line-height: 136%;}
		&:before{content: ""; width: 13px; height: 7px;background: url(/i/design/svg/ic-arrow-down.svg) no-repeat center top; position: absolute; bottom: 5px; left: 50%; z-index: 5;margin-left: -6px;}
		&:after{content: "";width: 100%;height: 18px;background: #90ccd1;position: absolute;bottom: 0;left: 0;}
}

p.open::before{transform: rotate(180deg);}


@media only screen and (max-width: 740px) {
  
  .thpt-panel{position: relative; width: 100%; margin: 0; top: 0;z-index: 400;}
  p.flip-thpt{padding: 10px 5% 20px 5%; width: auto;
	  img{margin: 0; width: auto; height: 40px; display: inline-block;}
  }
  div.panel{padding: 6%;
	  p{    font-size: .9em;}
  }
  
 }