// -----------------------------------//
// GLOBAL STYLES
// -----------------------------------//

// BODY
body                {color: #444; font-size: 13px; font-family: $bodyfont; text-align: center; line-height: 1.528571429;padding: 0px; margin: 0; background: #fff;
	//background: url(/i/design/design.jpg) no-repeat center top;opacity: .5;
	//background: url(/i/design/design-inside.jpg) no-repeat center top;opacity: .5;
}

// LINKS
body a              {color: $link-colour; text-decoration: none; transition: (all 0.3s ease-in-out);}
body a:hover, body a:focus {text-decoration: underline;}

// LINKED IMAGES
a img, img a, img, p a img 	{border: 0; border-bottom: 0; text-decoration: none; vertical-align: middle; }
a img:hover					{opacity: 0.8}

// HEADINGS
h1,h2,h3,h4,h5,h6	{font-weight: 500;}
h1, h2{font-family: $headings;}
h1					{font-size: 48px; margin: 0 0 20px 0; color: #000; line-height:1.2; }
h2					{font-size: 20px; margin: 25px 0 20px 0; color: #000; line-height:1.3; }
h3					{font-size: 17px; margin: 25px 0 10px 0; padding: 7px 10px 5px 15px; background: #30394e;color: #fff;}
h4					{@extend h3; background-position: 10px center; background-repeat: no-repeat; padding-left: 30px; }
h4.open 			{background-image: url(/i/design/expanding-faq/minus-white.png); }
h4.closed 			{background-image: url(/i/design/expanding-faq/plus-white.png); }
h5 					{font-size: 12px; }
h6, small 			{font-size: 10px; }
h1+h2				{margin-top:0; border:0; padding:0; }
h1+p, h2+p, h3+p	{margin-top:0; }

// PARAGRAPHS AND LIST ITEMS
p					{margin: 12px 0; padding: 0; }
li					{margin: 2px 0; padding: 0 0 0 23px; background-image: url(/i/icons/bullet_black.png); background-repeat: no-repeat; }

// FORMS
form 					{margin:0; padding:0; @extend .clearfix; }
fieldset 				{border: 1px solid #c0c0c0; margin: 0 2px; padding: 0.35em 0.625em 0.75em; }
legend 					{border: 0; padding: 0; white-space: normal; *margin-left: -7px; }
button, html input[type="button"], input[type="reset"], input[type="submit"] {-webkit-appearance: button; cursor: pointer; *overflow: visible; /* 4 */ }
button[disabled], html input[disabled] {cursor: default; }
input[type="checkbox"], input[type="radio"] {box-sizing: border-box; padding: 0; *height: 13px; *width: 13px; }
input[type="search"] 	{-webkit-appearance: textfield; box-sizing: content-box; }
input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration { -webkit-appearance: none; }
button::-moz-focus-inner, input::-moz-focus-inner {border: 0; padding: 0; }
textarea 				{resize: vertical; overflow: auto; vertical-align: top; }
label 					{float: left; margin: 10px 0; width: 90px; display: inline;}
form .formfield 	    {margin: 10px; float: left; display: inline; width: 200px; border: 1px solid #CCC; padding: 2px; }
form div			    {margin: 15px 0; display: block; clear: left; vertical-align: top; }
form div input[type="text"], form div textarea, form span textarea, form div input[type="textarea"], form div input[type="password"] {border: 1px solid #CCC; padding: 4px; width: 300px; margin: 0; background-color: #EFEFEF;}
input[type="text"]:focus, form div textarea:focus, form span textarea:focus, form div input[type="textarea"]:focus, form div input[type="password"]:focus {border-color: #000; }
form div span.label label, form div span.label,span.spacer,span.multiple span {width: 180px; float: left; margin: 0 0 0 0; } 
form div span.multiple  {float: left; } 
form div span.button    {padding-left: 180px; background: transparent; border: 0; } 
form div span.button input	{background: #666; color:#FFF; margin: 0; padding: 8px 10px; font-weight:bold; border: 0; }
form div span.button input:hover {background: #888; }
form div.clear 			{clear: both; padding-top: 5px; } 

// -----------------------------------//
// WRAPPER
// -----------------------------------//

#wrapper            {margin: 0 auto; text-align: center; }
.wrapper 			{width: 990px; margin: 0 auto; @extend .clearfix; }

// -----------------------------------//
// HEADER
// -----------------------------------//

header              {width: 100%;position: absolute;top: 0;left: 0;
	&:before{content: "";width: 100%;height: 400px;background: url(/i/design/bg-header.png) repeat-x center top;position: absolute;top: 0;left: 0;}
}
header > .wrapper   {padding: 0; position: relative;text-align: left;}

header h1 	        {margin: 0; font-size: 48px;display: inline-block;padding: 0 0;}
header h1 a       	{}
header h1 a:hover 	{}

#logobar{text-align: left;display: inline-block;height: 168px;position: relative; z-index: 1;
	h1{display: inline-block;
		a{display: inline-block;}
		span{display: inline-block;vertical-align: top;margin: 53px 0 0 11px; line-height: 68%;
			strong{display: block;font-weight: 400;font-size: 38px;color: #fff;}
			em{font-weight: 300;font-size: 29px;color: #fff;font-style: italic;}
		}
	}
}
img#logo{float: left;margin: 14px 0 0 -1px;}


//Top link
.top-links{position: absolute;top: 107px;right: 0;z-index: 1;
	ul{display: block;
		li{display: inline-block;vertical-align: top;margin: 0 0 0 11px;padding: 0;background: none;
			a{
				img{background: #fff;border-radius: 50%;}
			}
		}
	}
}


//Hero
body.body_2555 #hero{height: 900px;}
#hero{display: block;height: 490px;position: relative;}

// -----------------------------------//
// SEARCH FORM
// -----------------------------------//
#FS{display: inline-block;}
#FS input {outline: none;color: #fff;}
#FS input[type=search] {-webkit-appearance: textfield;-webkit-box-sizing: content-box;font-family: inherit;font-size: 100%;}
#FS input::-webkit-search-decoration,
#FS input::-webkit-search-cancel-button {display: none;}

#FS input[type=search] {color: transparent;cursor: pointer;background: url(/i/design/svg/ic-search.svg) no-repeat 0 center #fff;padding:0px;width: 26px;height: 26px;
	border-radius: 30px;
	transition: all .5s;}
#FS input[type=search]:hover {background-color: #fff;}

#FS input[type=search]:focus {width: 120px;background-color: #fff;padding-left: 32px;color: #000;cursor: auto;font-weight: 400;
	box-shadow:inset 2px 2px 0 0 rgba(0,0,0,.15);}
	
#FS input[type=search]:focus:-moz-placeholder {color: #000;font-weight: 300;-webkit-transition: .3s ease-out; transition: .3s ease-out;}
#FS input[type=search]:focus::-webkit-input-placeholder {color: #000;font-weight: 300;-webkit-transition: .3s ease-out; transition: .3s ease-out;}
#FS input:-moz-placeholder {color: transparent;-webkit-transition: .3s ease-out; transition: .3s ease-out;}
#FS input::-webkit-input-placeholder {color: transparent;-webkit-transition: .3s ease-out; transition: .3s ease-out;}


//Ofsted
.ofsted{width: 990px; position: absolute; bottom: 246px; left: 50%; margin-left: -496px; text-align: right;
	img{position: relative;
		animation: ofstedanim ease 3s;
		animation-iteration-count: 1;
		transform-origin: 50% 50%;
		animation-fill-mode:forwards; /*when the spec is finished*/
	}
}


@keyframes ofstedanim{
  0% {
    opacity:0;
    right: -30px;
  }
  100% {
    opacity:1;
    right: 0;
  }
}

//Sec Links
.sec-links{width: 100%;position: absolute;bottom: 0;right: 0;background: rgba(245, 245, 245, .3);padding: 30px 0 24px 0;
	ul{display: block;
		li{width: 230px;height: 120px;display: inline-block;vertical-align: top;margin: 0 10px;padding: 0;background: none;
			&:first-of-type{float: left;margin: 0;}
			&:last-of-type{float: right;margin: 0;}
			
			a{display: inline-block;width: 100%;height: 100%;text-align: center;background: #f5f5f5;box-shadow:inset 0 0 0 2px #fff;color: #30394e;position: relative;
				span{width: 100%;display: block;font-weight: 600;font-size: 19px;font-family: $headings;margin: 0 0 0 0;position: absolute;bottom: 17px;left: 0;}
				img{display: inline-block;margin:0;position: absolute;top: 13px; left: 75px;transition: (all 0.3s ease-in-out);}
				&:hover{color: #69c1ff;box-shadow:inset 0 0 0 4px #69c1ff;text-decoration: none;
					img{top: 7px;transition: (all 0.3s ease-in-out);opacity: 1;}
				}
			}
		}
	}
}

// -----------------------------------//
// SEARCH RESULTS
// -----------------------------------//
$search-highlight-background-color: #30394e;
$search-highlight-color: #fff;
$search-highlight-font-weight: bold;
$search-highlight-font-size: 15px;
$search-odd-tr-background: #fff;

.search-subject-box{display: inline-block; background: #f1f1f1; padding: 5px 15px; font-size: 16px; border-radius: 4px; border: 1px solid #ccc; text-transform: uppercase;}
.search-numbers{text-transform: uppercase; font-weight: bold; font-size: 12px;
	span{background: #f1f1f1; padding: 3px 8px; margin: 0 3px;border-radius: 2px; border: 1px solid #ccc; }
}

// -----------------------------------//
// GOOGLE TRANSLATE WIDGET 
// -----------------------------------//

#google_translate_element   {width: 95px;height: 26px;background: rgba(255, 255, 255, .7);float: right;position: relative;margin: 0 0 0 0;border-radius: 30px;
	//Translate text
	&:before{content: "Translate";position: absolute;left: 10px; top: 5px;color: #000;overflow: hidden;}
	//Translate arrow
	&:after{content: "";width: 26px; height: 26px;;position: absolute;top: 0; right: 0;background: url(/i/design/ic-translate.png) no-repeat center center #fff;border-radius: 50%;}
	
	//Select size
	.goog-te-gadget .goog-te-combo{margin: 0;width: 100%;height: 26px;}
}

.goog-te-gadget, .goog-te-combo{opacity: 0;}

// -----------------------------------//
// MAIN HOLDER
// -----------------------------------//

body.body_2555 #mainholder{width: 100%;padding: 0 0 0 0;background: #fff;}
#mainholder {width: 100%; margin: 0px auto; padding: 50px 0 30px 0; background: rgba(255, 255, 255, .95); clear: both; text-align: left; @extend .clearfix;
	.wrapper{
		.Content1Column{opacity: 1;}
		.Content2Column{opacity: 1;}
		.ColumnRight{opacity: 1;}
		.ColumnLeft{opacity: 1;}
	}
}


//Home wrapper
.home-wrapper{background: rgba(255, 255, 255, .5);margin: 0 0 0 0;position: relative;padding: 35px 0 50px 0;}

//Welcome txt
.welcome-txt{width: 400px;float: right;
	//Title
	h1{color: $h1colour;font-size: 31px; font-weight: 600; margin: 11px 0 20px -1px; position: relative; display: block; padding: 0 0 14px 0;
		&:before{content: "";width: 100%;height: 6px;background: $h1bottom-line-detail;border-radius: 10px;box-shadow:inset -351px 0 0 0 $h1bottom-line-bg;position: absolute;bottom: 0;left: -2px;}
	}
	
	//Paragraph
	p{color: #181739; font-size: 14px; font-weight: 300; margin: 23px 40px 0 -2px; letter-spacing: 0.02em;
		&:first-of-type{font-size: 16px; letter-spacing: 0.02em; margin: 21px 0 0 -2px; line-height: 127%;}
	}
	
	//Link
	a.read-more{background: #30394e; color: #fff; font-size: 16px; display: inline-block; margin: 20px 0 0 -1px; height: 30px; line-height: 30px; padding: 0 36px;
		&:hover{background: #69c1ff;text-decoration: none;}
	}
}

//Video home
.video-home{width: 540px;display: inline-block;}

// -----------------------------------//
// CONTENT COLUMNS
// -----------------------------------//

//Content 1
.Content1Column     {width: 990px; margin: 0; padding: 0; float: left; min-height:350px; text-align:left;opacity: 0;
	h1{color: #181739;font-size: 31px; font-weight: 600; margin: 21px 0 20px -1px; position: relative; display: block; padding: 0 0 14px 0;
		&:before{content: "";width: 100%;height: 6px;background: $h1bottom-line-detail;border-radius: 10px;box-shadow:inset -910px 0 0 0 $h1bottom-line-bg;position: absolute;bottom: 0;left: 0;}
	}
	h2{color: #30394e;font-size: 25px;}
}

//Content 2
.Content2Column     {width: 700px; margin: 0; padding: 0; float: left; min-height:350px; text-align:left;opacity: 0;
	h1{color: #181739;font-size: 31px; font-weight: 600; margin: 21px 0 20px -1px; position: relative; display: block; padding: 0 0 14px 0;
		&:before{content: "";width: 100%;height: 6px;background: $h1bottom-line-detail;border-radius: 10px;box-shadow:inset -640px 0 0 0 $h1bottom-line-bg;position: absolute;bottom: 0;left: 0;}
	}
	h2{color: #30394e;font-size: 25px;}
}

//Content 3
.Content3Column     {width: 430px; margin: 0; padding: 0; float: left; min-height:350px; text-align:left; }

// -----------------------------------//
// COLUMN LEFT & COLUMN RIGHT
// -----------------------------------//

.ColumnLeft			{width: 250px; margin: 0; padding: 0 0 0 0; min-height:250px; text-align:left; float: left; opacity: 0;}
.ColumnRight		{width: 250px; margin: 0; padding: 0 0 0 0; min-height:250px; text-align:left; float: right; opacity: 0;}

.ColumnLeft ul, .ColumnRight ul {margin: 0; padding: 0; list-style: none; }
.ColumnLeft nav ul a:link, .ColumnRight nav ul a:visited, .ColumnRight nav ul a:link, .ColumnRight nav ul a:visited {display: block; }
.ColumnLeft li 		        	{border-bottom: 1px solid #EEE; } // Hack to fix IE/Win's broken rendering of block-level anchors in lists
html > body .ColumnLeft li    	{border-bottom: none; } // Fix for browsers that don't need the hack


//Twitter holder
#twitterholder{height: 200px;overflow: hidden;
	ul{display: block;margin: 0;padding: 0;
		li{margin: 0 0 20px 0;padding: 0 0 20px 0;background: none;box-shadow: inset 0 -1px 0 0 #465677;}
		
		.tweet{color: #fff;}
		.media{display: block;
			img{width: 100%;}
		}
	}
}

// -----------------------------------//
// SIDE COLUMN > LIST SECTIONS
// -----------------------------------//

// Container
.SideHeading            	  { margin: 0 0 0 0; padding: 0;text-align: center;}
.SideHeading:first-child 	  { margin-top: 0; }

// Heading
.SideHeading h2         	  { display: block;text-align: center;font-size: 24px;padding: 20px 0;margin: 0;}

// List
ul.SideList             	  { margin:0 0 0 0; padding: 0; border: 0; list-style: none; text-align: left;}
ul.SideList li         		  { margin:0 0 0 0; padding: 0; background: none;}

ul.SideList li a       		  { margin: 0; padding: 5px 10px; color: #333; font-size: 0.89em; font-weight: normal;display: block; }
ul.SideList li a:hover  	  { text-decoration: none;}
ul.SideList li a.child        { background-image: url(/i/design/svg/ic-arrow-related.svg);background-position: 15px 15px;background-repeat: no-repeat; padding-left: 43px !important;
	&:hover{background-color: #525a6d;}
}


// Date and Title
ul.SideList li p        { margin: 0; padding: 0; }
ul.SideList p.date      { float: left; text-align: left;font-size: 14px;line-height: 130%;
	span{display: block;font-weight: bold;font-size: 22px;margin: 7px 0 0 0;}
}
ul.SideList p.title     { float: right; width: 75%;font-size: 15px;}

// More links
.SideHeading a.more     {display: inline-block;font-size: 15px;padding: 15px 10px;}

//Diary
#SideHeadingDiary{display: inline-block;background: url(/i/design/bg-news.jpg) no-repeat center top;background-size: cover;box-shadow:inset 0 0 0 4px #fff;
	
	//Title
	h2{
		&:before{content: "";width: 100%;height: 6px;background: $gbl-detail;border-radius: 10px;box-shadow:inset -200px 0 0 0 $gbl-bg;position: absolute;bottom: 0;left: -2px;}
	}
	
	//List
	ul.SideList{display: block;
		li{padding: 0 0 15px 0; margin: 0 0 11px 0;box-shadow:inset 0 -1px 0 0 #6b707f;
			&:last-of-type{margin: 0;}
			a{color: #fff;padding: 0;
				&:hover{color: #69c1ff;}
			}
			
			p.title{width: 76%;height: 45px;font-size: 14px;letter-spacing: 0.02em;display: table;
				span{display: table-cell;vertical-align: middle;}
			}
		}
	}	
	
	//More
	.box-more{
		a{background: #525a6d;
			&:hover{background: #69c1ff;text-decoration: none;}
		}
	}
}

//News
#SideHeadingNews{background: url(/i/design/bg-news.jpg) no-repeat center top;background-size: cover;float: left;box-shadow:inset 0 0 0 4px #fff;
	
	//Title
	h2{
		&:before{content: "";width: 100%;height: 6px;background: $gbl-detail;border-radius: 10px;box-shadow:inset -200px 0 0 0 $gbl-bg;position: absolute;bottom: 0;left: -2px;}
	}
	
	//List
	ul.SideList{display: block;
		li{padding: 0 0 15px 0; margin: 0 0 13px 0;box-shadow:inset 0 -1px 0 0 #2d364a;
			&:last-of-type{margin: 0;}
			a{color: #fff;padding: 0;
				&:hover{color: #69c1ff;}
			}
			
			p.title{width: 88%;font-size: 14px;letter-spacing: 0.02em;}
		}
	}
	
	//More
	.box-more{text-align: center;
		a{background: #525a6d;display: inline-block;float: none;
			&:hover{background: #69c1ff;text-decoration: none;}
		}
	}
	
}

//Related pages
#SideHeadingPages{background: url(/i/design/bg-related-pages.jpg) no-repeat center top;background-size: cover;box-shadow:inset 0 0 0 4px #fff;padding: 4px;display: block;
	//Title
	h2{color: #fff;text-align: left;padding: 0;position: relative;padding: 0 0 13px 0; margin: 15px 20px 24px 15px;
		&:before{content: "";width: 100%;height: 6px;background: $gbl-detail;border-radius: 10px;box-shadow:inset -160px 0 0 0 $gbl-bg;position: absolute;bottom: 0;left: 0;}
	}
	
	//List
	ul.SideList{display: block;
		li{padding: 0; margin: 0;
			&:last-of-type{margin: 0;}
			a{color: #fff;padding: 8px 15px;font-size: 16px;
				&:hover{background-color: #525a6d;text-decoration: none;}
			}
			
			p.title{width: 88%;font-size: 14px;letter-spacing: 0.02em;}
		}
	}
}


//Bottom links
.bottom-links{background: url(/i/design/bottom-links-bg.png) repeat-x center top #fff;display: block;padding: 40px 0;
	//Wrap content
	.wrap-content{width: calc(1080px - 90px);background: #e7e7e7;margin: 0 0 0 -46px;padding: 30px 45px 40px 45px;display: inline-block;text-align: center;}
	
	//Side heading
	.SideHeading{width: calc(310px - 60px);min-height: 310px;;display: inline-block;vertical-align: top;text-align: left;padding:30px 30px 19px 30px;
		//Title
		h2{margin: -12px 0 25px 1px;padding: 0 0 16px 0;color: #fff;text-align: left;font-size: 29px;font-weight: 600;line-height: normal;position: relative;}
		
		//Box more
		.box-more{display: block;text-align: left;margin: 18px 0 0 0;
			a{width: 120px;height: 30px;line-height: 32px;display: inline-block;text-align: center;color: #fff;font-size: 16px;padding: 0;
				&:last-of-type{float: right;}
			}
		}
	}
	
	//Box link
	.box-link{width: 310px;float: right;padding: 0;text-align: left;
		//Title
		h2{margin: 0 0 0 -1px; padding: 10px 0 6px 0; color: #30394e; font-weight: 600; font-size: 35px; line-height: 91%; letter-spacing: 0.015em;
			span{display: block;font-size: 20px;font-weight: 300;}
		}
		p{color: #30394e; font-size: 15px; margin: 2px 0 11px -1px; line-height: 120%;}
		
		a.find-more{background: #65abb1;color: #fff;text-align: center;display: inline-block;font-size: 16px;height: 30px; line-height: 33px; padding: 0 23px; letter-spacing: 0.02em;
			&:hover{background: #40495c;text-decoration: none;}
		}
		
		//Join our teams
		.join-our-team{height: 170px;display: block;background: url(/i/design/bg-our-team.jpg) no-repeat center top;background-size: cover;margin: 0 0 20px 0;box-shadow:inset 10px 0 0 0 #40495c;padding: 0 0 0 30px;}
		
		//Teaching box
		.teaching-box{height: 170px;display: block;background: url(/i/design/bg-teaching-box.jpg) no-repeat center top;background-size: cover;box-shadow:inset 10px 0 0 0 #40495c;padding: 0 0 0 30px;}
	}
}


.fc-header{
	&:last-of-type{display: none;}
}
.fc-content{
	&:last-of-type{display: none;}
}

// -----------------------------------//
// HOME PAGE ALERT
// -----------------------------------//

//Modal
.modal-home{background: rgba(0, 0, 0, .4);position: fixed;top: 0;left: 0;width: 100%;height: 100%;z-index: 8000;
	.message-modal{position: absolute;top: calc(50% - 300px);left: calc(50% - 465px - 40px - 10px);width: 930px;height: auto; margin: 0 30px; padding: 20px;background: rgba(255, 255, 255, .9);
		.modal-content{background: rgba(255, 255, 255, .9);background-position: -120px 50px ;box-shadow:inset 0 0 0 1px #fff;display: inline-block;width: 100%;height: 100%;text-align: center;
			.modal-text{display: block; padding: 45px;
				p{margin: 0;}
			}
			a.close-modal{position: absolute;top: 31px;right: 31px;}
		}
	}
}

.hide-modal{display: none !important;}



// -----------------------------------//
// FOOTER
// -----------------------------------//

footer                { clear: both; }
footer > .wrapper     { margin: 0 auto; padding: 20px 0px; color: #000; text-align: left; }
footer > .wrapper p   { margin: 5px 0; display: inline-block;}
footer > .wrapper a   { color: #69c1ff;}

#credit        { margin: 0; padding: 0; float: right; text-align:right;font-size: 12px;}


//Sec info
.sec-info{background: $footer-bg;padding: 40px 0 47px 0;text-align: left;
	//Title
	h1{color: #fff;text-align: left;display: block;font-size: 31px;font-weight: 600;position: relative;padding: 0 0 14px 0;margin: 0 0 17px 0;
		&:before{content: "";width: 178px;height: 6px;background: $gbl-detail;border-radius: 10px;box-shadow:inset -127px 0 0 0 $gbl-bg;position: absolute;bottom: 0;left: -2px;}
	}
	
	//Paragraph
	p{color: #fff;font-size: 15px;display: inline-block;letter-spacing: 0.01em;margin: 11px 50px 0 0;}
	
	a{color: #69c1ff;}
}


//Sec copyright
.sec-copyright{background: #fff; padding: 28px 0 28px 0; line-height: 170%; letter-spacing: 0.01em;
	p{margin: 0;color: #767a83;font-size: 13px;}
}


// -----------------------------------//
// CMS CONTENT: TABLES
// -----------------------------------//

$table-highlight-color: #fff;
$table-highlight-background-color: #30394e;
$table-highlight-font-weight: bold;
$table-border-color: #ccc;

// -----------------------------------//
// CMS CONTENT: BLOCKQUOTES
// -----------------------------------//

$blockquote-background-color: #fff;
$blockquote-border-color: #EBEBEB;
$blockquote-color: #333;
$blockquote-speech-mark-color: #999;
$blockquote-font-size: 1em;

// -----------------------------------//
// CALENDAR TABLE BACKGROUND
// -----------------------------------//

.fc-content { background: none; }


// -----------------------------------//
// BROWSER DETECTION
// -----------------------------------//

#browserblast         { position: fixed; top: 0; left: 0; width: 100%; height: 40px; border-bottom: 1px solid #D4C790; font-family: sans-serif; font-size: 12px; font-weight: bold; line-height: 40px; color: #6d6647; text-align: center; background: #FAF4AF; }
#browserblast a       { color: green; text-decoration: underline; }
.unsupported-browser  { margin-top: 41px; }

// -----------------------------------//
// FAQ SCRIPT
// -----------------------------------//

// h3.faq-open { background: #EFEFEF url(/i/design/expanding-faq/minus.png) right 12px center no-repeat; }
// h3.faq-closed { background: #EFEFEF url(/i/design/expanding-faq/plus.png) right 12px center no-repeat; }

// -----------------------------------//
// SWITCH MOBILE
// -----------------------------------//

#switch-mobile      { width:100%; margin: 0; padding: 20px 0; background:#EFEFEF; border-top:1px solid #EBEBEB; border-bottom:1px solid #EBEBEB; text-align:center; color:#777; font-family: sans-serif; font-size: 12px; display:block; clear:both; }
#switch-mobile a    { text-decoration: underline; }

//Scrolling ticker
.scrolling-ticker{width: 100%;position: absolute;bottom: 174px;left: 0;background: $scrolling-ticker-bg;}

/* Ticker */
#ticker-wrapper {width: 100%;height: 50px;overflow: hidden;margin: 0;display: block;text-align: right;
	//Title
	img{float: left;background: #90ccd1;margin: 11px 0 0 0px;}
	
	//Ticker container
	.tickercontainer {width: 950px;height: 100%;margin: 0 0 0 0;padding: 0;overflow: hidden;display: inline-block;
		.mask {width: 100%;height: 100%;position: relative;margin: 0 auto;float: right;overflow: hidden;
			//List
			ul.newsticker {position: relative;left: 990px;line-height: 54px;list-style-type: none;margin: 0;padding: 0;height: 100%;
				li {float: left;margin: 0;padding: 0 50px 0 0;background: none;white-space: nowrap;color: #fff;font-size: 18px;font-weight: 300;
					a {text-decoration: none;color: #009639;
						&:hover {color: #009639;text-decoration: underline;}
					}
				}
			}
		}
	}
}

/* Scrollbar */
.mCustomScrollbar{ -ms-touch-action: none; touch-action: none; /* MSPointer events - direct all pointer events to js */ }
.mCustomScrollbar.mCS_no_scrollbar, .mCustomScrollbar.mCS_touch_action{ -ms-touch-action: auto; touch-action: auto; }
.mCustomScrollBox{ /* contains plugin's markup */position: relative;overflow: hidden;height: 100%;max-width: 100%;outline: none;direction: ltr;}

.mCSB_container{ /* contains the original content */overflow: hidden;width: auto;height: auto;padding: 0 20px 0 0;}
.mCSB_inside > .mCSB_container{ margin-right: 0; }
.mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden{ margin-right: 0; } /* non-visible scrollbar */
.mCS-dir-rtl > .mCSB_inside > .mCSB_container{ /* RTL direction/left-side scrollbar */margin-right: 0;margin-left: 30px;}
.mCS-dir-rtl > .mCSB_inside > .mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden{ margin-left: 0; } /* RTL direction/left-side scrollbar */

.mCSB_scrollTools{ /* contains scrollbar markup (draggable element, dragger rail, buttons etc.) */
position: absolute;width: 10px;height: auto;left: auto;top: 0;right:0;bottom: 0;}
.mCSB_outside + .mCSB_scrollTools{ right: -26px; } /* scrollbar position: outside */
.mCS-dir-rtl > .mCSB_inside > .mCSB_scrollTools, 
.mCS-dir-rtl > .mCSB_outside + .mCSB_scrollTools{ /* RTL direction/left-side scrollbar */
right: auto;left: 0;}
.mCS-dir-rtl > .mCSB_outside + .mCSB_scrollTools{ left: -26px; } /* RTL direction/left-side scrollbar (scrollbar position: outside) */



.mCSB_scrollTools .mCSB_draggerContainer{ /* contains the draggable element and dragger rail markup */
/* ------colours----- */
background: #9199ad;    border-radius: 10px;
/* -------- */
position: absolute;top: 0;left: 0;bottom: 0;right: 0; height: auto;}

.mCSB_scrollTools a + .mCSB_draggerContainer{ margin: 20px 0;}
.mCSB_scrollTools .mCSB_draggerRail{width: 2px;height: 100%;margin: 0 auto;-webkit-border-radius: 16px; -moz-border-radius: 16px; border-radius: 16px;}

.mCSB_scrollTools .mCSB_dragger{ /* the draggable element */   
/* ------colours----- */
background: #69c1ff;    border-radius: 10px;
/* -------- */
cursor: pointer;width: 100%;height: 30px; /* minimum dragger height */z-index: 1;}