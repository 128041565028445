// Global Variables
$bodyfont: 'Avenir', sans-serif;
$headings: "lexia",serif;

// -----------------------------------//
// Colour Variables
// -----------------------------------//

//Links
$link-colour: #69c1ff;

//Nav
$nav-bg: rgba(48, 58, 78, .9);
$dop-down-bg:#303a4e;
$dop-down-hover:#1c2331;

//Quick links: Colours are in c_quicklinks.scss

//Scrolling ticker
$scrolling-ticker-bg: rgba(0,0,0,0.5);

//H1s
$h1colour: #181739;
$h1bottom-line-bg:#2f394e;
$h1bottom-line-detail: #69c1ff;

//General bottom line
$gbl-detail:#69c1ff;
$gbl-bg: #9199ad;
$diary-detail: #30394e;


//Footer
$footer-bg: #303a4e;

// CMS UI Styles
@import 'c_ui_styles';

// Partial Imports
@import 'c_reset';
@import 'c_site';
// @import 'c_site_sidebar';
@import 'c_default';
@import 'c_insidepages';
@import 'c_slider';
@import 'c_cmsstyles';
@import 'c_thpt';
@import 'c_fonts';


// Mobile / Responsive Imports

@import 'c_meanmenu';
@import 'c_respond';


//Quick links widget
@import 'c_quicklinks';


