//iPad basic
@media only screen and (max-width: 1024px) {
	
		//Wrapper
	.wrapper{width: 950px;}
	
	//Nav wrapper
	nav#navholder > .wrapper{width: 100%;}
	ul.nav{width: 100%;}
	
	
	//Mainholder
	#mainholder{width: 100%;}
	
	//Contents
	.Content1Column{width: 950px;}
	.Content2Column{width: 630px;}
	
	.bottom-links .wrap-content{width: 100%;padding: 0;margin: 0;background: none;}
	#ticker-wrapper .tickercontainer{width: 910px;}
	
	.sec-links ul li{width: 220px;}
	.video-home{width: 460px;
		img{width: 100%;}
	}
	
	.ofsted{width: 950px;}
	
}

// Mobile Version

@media only screen and (max-width: 770px) {

	// Body

	body {
	 	min-width: 0;
	 	overflow-x: hidden;
	 	//font-size: 13px;
	}

	// Global

	img {
		max-width: 100%;
		height: auto;
	}
	table {
		max-width: 100%;
	}
	
	//Responsive tables
	table.tableborder{width: 100%; overflow: scroll; white-space: nowrap; table-layout: fixed;position: relative;
		&:before{content: "";width: 30px;height: 100%;position: absolute;top: 0;right: -1px;
			background: -moz-linear-gradient(left,  rgba(255,255,255,0) 0%, rgba(0,0,0,0.2) 100%);
			background: -webkit-linear-gradient(left,  rgba(255,255,255,0) 0%,rgba(0,0,0,0.2) 100%);
			background: linear-gradient(to right,  rgba(255,255,255,0) 0%,rgba(0,0,0,0.2) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#33000000',GradientType=1 );
		}
		tbody{width: 100%; float: left; white-space: normal; overflow-x: scroll;}
	}

	// Wrappers
	.wrapper {
		width: 100%;
	}
	
	nav#navholder{position: fixed;top: 0;left: 0;width: 100%;}
	.mean-container .mean-bar {max-height: 100%; position: fixed; overflow-y: scroll;}



	// Calendar

	#calendar {
	    .fc-header td {
	        display: block;
	        width: 100%;
	    }
	    h2 {} .fc-button-content {
	        font-family: Arial;
	    }
	    .fc-header-right {
	        text-align: left;
	    }
	    .fc-header-center {
	        text-align: left;
	    }
	}

	// Forms

	form div input[type="text"], form div textarea, form span textarea, form div input[type="textarea"], form div input[type="password"] {
	    width: 200px;
	}
	form div span.button {
	    padding-left: 0;
	}
	
	//Search Form
	#FS{margin: 0;float: none;display: block;}
	
	// Header
	header{padding: 44px 0 0 0;position: relative;
		.wrapper{padding: 0;}
	}
	
	//Hero
	body.body_2555 #hero{height: auto;
		.ofsted{width: 100%;bottom: auto;left: auto;margin-left: auto;}
		.scrolling-ticker{width: 90%;padding: 0 5%;position: relative;bottom: auto;}
		.sec-links{width: 90%;padding:30px 5%;position: relative;bottom: auto;
			ul{
				li{width: 100%;margin: 0;height: auto;
					a{margin: 0 0 20px 0;height: 120px;}
				}
			}
		}
	}
	#hero{width: 100%;height: auto;}
	
	#ticker-wrapper .tickercontainer{width: 88%;}
	#ticker-wrapper .tickercontainer .mask ul.newsticker{left: 300px;
		li{font-size: 16px;}
	}
	
	//Logobar
	#logobar{height: auto;display: block;text-align: center;
		h1{
			span{margin: 0;
				strong{font-size: .6em; width: 80%; margin: 0 10%;}
				em{font-size: .4em;display: block;margin: -2px 0 0 0;}
			}
		}
	}
	img#logo{float: none; margin: 10px 0 0 0; height: 120px; width: auto;}
	
	//Top link
	.top-links{position: relative;top: auto;margin: 10px 0 10px 0;
		ul{text-align: center;
			li{
				&:first-of-type{width: 43%;}
				&:nth-of-type(2){width: 43%; margin: 0 11px 10px 0;}
			}
		}
	}
	
	#FS input[type=search]{width: 100%;}
	
	#google_translate_element{width: 100%;}
	
	.ofsted{text-align: center;position: relative;margin: 0 0 10px 0;
		img{float: none;width: auto;height: 70px;}
	}
	
	//Slider
	body.body_2555 #slider{position: fixed;}
	#slider{width: 100%; padding: 0; margin: 0;height: 100%;display: inline-block;top: 0;left: 0;}
	.nivoSlider{position: relative;}
	
	// Mainholder
	#mainholder{width: 100%;padding: 0;}
	
	// Contents
	.Content1Column,
	.Content2Column,
	.Content3Column{width: 84%; padding: 8%; margin: 0;}
	
	// Col Left and Right
	.ColumnLeft,
	.ColumnRight{width: 84%; padding: 8%; margin: 0;}
	
	//Home wrapper
	.home-wrapper{width: 90%; padding: 20px 5%;}
	.welcome-txt{width: 100%;
		p{margin: 10px 0;}
		a.read-more{margin: 10px 0;}
	}
	.video-home{width: 100%;}
	
	//Bottom links
	.bottom-links{width: 90%;padding: 30px 5%;
		.wrap-content{width: 90%;padding: 20px 5%;margin: 0;}
		.SideHeading{width: 80%;padding: 30px 10% 15px 10%;margin: 0 0 20px 0;
			.box-more{
				a{width: 100%;margin: 0 0 10px 0;}
			}
		}
		.box-link{width: 100%;}	
	}
	
	
	.sec-info{padding: 20px 0;
		p{display: block; margin: 0 0 15px 0; font-size: 1em;}
	}
	
	#credit{display: block;float: none;text-align: left;}
	
	
	.sec-copyright{padding: 10px 0;}
	
	//News diary
	#SideHeadingDiary h2:before{box-shadow: inset -140px 0 0 0 #9199ad;}
	#SideHeadingNews h2:before{box-shadow: inset -140px 0 0 0 #9199ad;}
	
	//Home page alert
	.modal-home{
		.message-modal{width: 90%; margin: 0 5%; left: 0; top: 20%; padding: 0;
			.modal-content{
				.modal-text{padding: 3%;}
				a.close-modal{position: absolute; top: 3%; right: 3%; background: #fff; padding: 3px; border-radius: 0px 0px 0px 7px;}
			}
		}
	}
	
	// Footer
	footer{
		.wrapper{width: 90%;padding: 5%;}
	}
	
	
}