// ********************************************************************************
// ********************************************************************************
// ********************************************************************************
// ********************************************************************************
// ********************************************************************************
// 									HELVETICA
// ********************************************************************************
// ********************************************************************************
// ********************************************************************************
// ********************************************************************************
// ********************************************************************************

// -----------------------------------//
// LIGHT
// -----------------------------------//

@font-face {
  font-family: 'Avenir';
  src: url('/inc/font-face/avenir/light/AvenirLTStd-Light.eot'); /* IE9 Compat Modes */
  src: url('/inc/font-face/avenir/light/AvenirLTStd-Light.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/inc/font-face/avenir/light/AvenirLTStd-Light.woff') format('woff'), /* Modern Browsers */
       url('/inc/font-face/avenir/light/AvenirLTStd-Light.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('/inc/font-face/avenir/light/AvenirLTStd-Light.svg#AvenirLTStd-Light') format('svg'); /* Legacy iOS */
       
  font-style:   normal;
  font-weight:  300;
}

@font-face {
  font-family: 'Avenir';
  src: url('/inc/font-face/avenir/light-italic/AvenirLTStd-LightOblique.eot'); /* IE9 Compat Modes */
  src: url('/inc/font-face/avenir/light-italic/AvenirLTStd-LightOblique.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/inc/font-face/avenir/light-italic/AvenirLTStd-LightOblique.woff') format('woff'), /* Modern Browsers */
       url('/inc/font-face/avenir/light-italic/AvenirLTStd-LightOblique.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('/inc/font-face/avenir/light-italic/AvenirLTStd-LightOblique.svg#AvenirLTStd-LightOblique') format('svg'); /* Legacy iOS */
       
  font-style:   italic;
  font-weight:  300;
}


// -----------------------------------//
// REGULAR
// -----------------------------------//

@font-face {
  font-family: 'Avenir';
  src: url('/inc/font-face/avenir/roman/AvenirLTStd-Roman.eot'); /* IE9 Compat Modes */
  src: url('/inc/font-face/avenir/roman/AvenirLTStd-Roman.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/inc/font-face/avenir/roman/AvenirLTStd-Roman.woff') format('woff'), /* Modern Browsers */
       url('/inc/font-face/avenir/roman/AvenirLTStd-Roman.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('/inc/font-face/avenir/roman/AvenirLTStd-Roman.svg#AvenirLTStd-Roman') format('svg'); /* Legacy iOS */
       
  font-style:   normal;
  font-weight:  400;
}

@font-face {
  font-family: 'Avenir';
  src: url('/inc/font-face/avenir/italic/AvenirLTStd-Oblique.eot'); /* IE9 Compat Modes */
  src: url('/inc/font-face/avenir/italic/AvenirLTStd-Oblique.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/inc/font-face/avenir/italic/AvenirLTStd-Oblique.woff') format('woff'), /* Modern Browsers */
       url('/inc/font-face/avenir/italic/AvenirLTStd-Oblique.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('/inc/font-face/avenir/italic/AvenirLTStd-Oblique.svg#AvenirLTStd-Oblique') format('svg'); /* Legacy iOS */
       
  font-style:   italic;
  font-weight:  400;
}

// -----------------------------------//
// Medium
// -----------------------------------//

@font-face {
  font-family: 'Avenir';
  src: url('/inc/font-face/avenir/medium/AvenirLTStd-Medium.eot'); /* IE9 Compat Modes */
  src: url('/inc/font-face/avenir/medium/AvenirLTStd-Medium.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/inc/font-face/avenir/medium/AvenirLTStd-Medium.woff') format('woff'), /* Modern Browsers */
       url('/inc/font-face/avenir/medium/AvenirLTStd-Medium.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('/inc/font-face/avenir/medium/AvenirLTStd-Medium.svg#AvenirLTStd-Medium') format('svg'); /* Legacy iOS */
       
  font-style:   normal;
  font-weight:  500;
}

@font-face {
  font-family: 'Avenir';
  src: url('/inc/font-face/avenir/medium-italic/AvenirLTStd-MediumOblique.eot'); /* IE9 Compat Modes */
  src: url('/inc/font-face/avenir/medium-italic/AvenirLTStd-MediumOblique.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/inc/font-face/avenir/medium-italic/AvenirLTStd-MediumOblique.woff') format('woff'), /* Modern Browsers */
       url('/inc/font-face/avenir/medium-italic/AvenirLTStd-MediumOblique.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('/inc/font-face/avenir/medium-italic/AvenirLTStd-MediumOblique.svg#AvenirLTStd-MediumOblique') format('svg'); /* Legacy iOS */
       
  font-style:   italic;
  font-weight:  500;
}

// -----------------------------------//
// BOLD
// -----------------------------------//

@font-face {
  font-family: 'Avenir';
  src: url('/inc/font-face/avenir/heavy/AvenirLTStd-Heavy.eot'); /* IE9 Compat Modes */
  src: url('/inc/font-face/avenir/heavy/AvenirLTStd-Heavy.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/inc/font-face/avenir/heavy/AvenirLTStd-Heavy.woff') format('woff'), /* Modern Browsers */
       url('/inc/font-face/avenir/heavy/AvenirLTStd-Heavy.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('/inc/font-face/avenir/heavy/AvenirLTStd-Heavy.svg#AvenirLTStd-Heavy') format('svg'); /* Legacy iOS */
       
  font-style:   normal;
  font-weight:  600;
}

@font-face {
  font-family: 'Avenir';
  src: url('/inc/font-face/avenir/heavy-italic/AvenirLTStd-HeavyOblique.eot'); /* IE9 Compat Modes */
  src: url('/inc/font-face/avenir/heavy-italic/AvenirLTStd-HeavyOblique.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/inc/font-face/avenir/heavy-italic/AvenirLTStd-HeavyOblique.woff') format('woff'), /* Modern Browsers */
       url('/inc/font-face/avenir/heavy-italic/AvenirLTStd-HeavyOblique.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('/inc/font-face/avenir/heavy-italic/AvenirLTStd-HeavyOblique.svg#AvenirLTStd-HeavyOblique') format('svg'); /* Legacy iOS */
       
  font-style:   italic;
  font-weight:  600;
}

